import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './article-preview.module.css'

export default ({ article }) => (
  <div className={styles.preview}>
    <Link
      className={styles.link}
      to={`/${article.slug}`}
    >
      <Img
        alt=""
        fluid={article.heroImage.fluid}
      />
      <h3 className={styles.previewTitle}>
          {article.title}
      </h3>
      <h5 className={styles.tagline}>
        {article.tagline}
      </h5>
    </Link>
    {/*<div
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    >
    </div>*/}
  </div>
)
