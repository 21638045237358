import React, { Component } from 'react'
import classNames from 'classnames';
import { Link } from 'gatsby'
import styles from './navigation.module.css'
import SignUpModal from './SignUpModal';

//<li className={styles.navigationItem}>
export default class Navigation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      signUpModalOpen: false,
    };
  }

  toggleSignUpModal = () => {
    let { signUpModalOpen } = this.state;
    this.setState({ signUpModalOpen: !signUpModalOpen });
  }

  render() {
    return (
      <nav role="navigation">
        <ul className={styles.navigation}>
          <li className={styles.navigationItem}>
            <Link to="/">Zoobongo</Link>
          </li>
          <li
            className={classNames(styles.navigationItem, styles.mlAuto, 'cursor-pointer')}
            onClick={this.toggleSignUpModal}
          >
            Sign Up
          </li>
          {/*<li className={styles.navigationItem}>
            <Link to="/blog/">Log in</Link>
          </li>*/}
        </ul>
        <SignUpModal
          isOpen={this.state.signUpModalOpen}
          closeModal={this.toggleSignUpModal}
        />
      </nav>
    );
  }
}
