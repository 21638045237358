import React, { Component } from 'react';
import FloatingLabel from 'floating-label-react';
import 'floating-label-react/styles.css';
import { Formik } from 'formik';
import Modal from 'react-modal';
// import superagent from 'superagent';
// const superagent = require('superagent');
import axios from 'axios';

import styles from './sign-up-modal.module.css'

Modal.setAppElement('#___gatsby');

export default class SignUpModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Modal
        className={styles.modal}
        contentLabel="Sign Up Modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}
             >
        <h4>
          Sign up
        </h4>
        <svg
          className={styles.closeBtn}
          height="28"
          onClick={this.props.closeModal}
          viewBox="0 0 36 36"
          width="28"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z">
          </path>
        </svg>
        <Formik
          initialValues={{ email: '', firstName: '', lastName: '', password: '' }}
          validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            const { email, firstName, lastName, password } = values;
            axios.get('https://us-central1-zoobongo-prod.cloudfunctions.net/createUser', {
              params: {
                email,
                firstName,
                lastName,
                password,
              }
            }).then((err, res) => {
                if (err) { return console.log(err); }
                console.log('res', res);
                console.log('err', err);
                // console.log(res.body.url);
                // console.log(res.body.explanation);
                setSubmitting(false);
              });

            /*setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);*/
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <FloatingLabel
                id="first-name"
                name="firstName"
                placeholder="First Name"
                type="text"
                value={values.firstName}
                onChange={handleChange}
              />
              <FloatingLabel
                id="last-name"
                name="lastName"
                placeholder="Last Name"
                type="text"
                value={values.lastName}
                onChange={handleChange}
              />
              <FloatingLabel
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                value={values.email}
                onChange={handleChange}
              />
              <span className={styles.errorTxt}>
                {errors.email && touched.email && errors.email}
              </span>
              <FloatingLabel
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                value={values.password}
                onChange={handleChange}
              />
              {errors.password && touched.password && errors.password}
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </form>
          )}
        </Formik>
      </Modal> 
    );
  }
}